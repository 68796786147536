import React, { useEffect, useState} from "react"

import { Widget, addResponseMessage, toggleInputDisabled} from 'react-chat-widget';
import Client from "@twilio/conversations"

import 'react-chat-widget/lib/styles.css';
import "./ChatWidget.css"


// const ChatWidget: React.FC<{config: any}> = ({config}) => {
// const ChatWidget = (config: { config: any; }) => {

const ChatWidget = ({ config }: { config: any }) => {
  const [token, setToken] = useState<string>();
  const [client, setClient] = useState<Client>();
  const [conversation, setConversation] = useState<any>();

  useEffect(() => {
    toggleInputDisabled();
  }, [])

  useEffect(() => {
    const fetchToken = async () => {
      console.log("Client Id: " + config.client_id);
      fetch(process.env.REACT_APP_CONVERSATION_TOKEN_URL || "",{
        method: "POST",
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json"
        },
        body: JSON.stringify({
          identity: config.user_name,
          client_id: config.client_id
        })
      }).then(response => {
        response.text().then(text => {
          console.log(`Response Text: ${text}`);
          let data = JSON.parse(text);
          setToken(data.token)
        }).catch(error => {
          console.log("Error Received: " + error);
        })
      })
    }
    fetchToken();

  },[config])

  useEffect( () => {
    const createClient = async () => {
      console.log("Token is: " + token);
      if(token) {
        const newClient = await Client.create(token);
        setClient(newClient);
      }
    }
   createClient();
  }, [token])

  useEffect( () => {
    const createConversation = async () => {
      const newConversation = await client?.createConversation();
      if(newConversation) {
        await newConversation?.join();
        addResponseMessage(config.intro_text)
        toggleInputDisabled();
        console.log(newConversation)
        newConversation.on("messageAdded", (message: any) => {
          if(message.author !== config.user_name){
            let formattedMessage = `**${message.author}**: ${message.body}`
            addResponseMessage(formattedMessage)
          }
        })
        setConversation(newConversation)
      }
    }

    createConversation();

  }, [client, config])

  const handleNewUserMessage = (message: string) => {
    const attributes = {
      "client_id": config.client_id
    }
    conversation?.sendMessage(message, attributes);
  }
  
  return (
    <Widget 
      title={config.title}
      subtitle={config.subtitle}
      handleNewUserMessage={handleNewUserMessage} 
    />
  )
}

export default ChatWidget;