import React from 'react';
import ChatWidget from './chat-widget/ChatWidget';

function App() {
    const config = {
        client_id: "49bd6f23-57be-4a8a-9af2-965a8210311d",
        title: "Meridian Engage",
        subtitle: "User’s Conference 2021",
        user_name: "Customer",
        intro_text: "**Coweta-Fayette Virtual Agent**: How may I help you today?"
    }
    return (
        <div className="App">
          <ChatWidget config={config}/>
        </div>
    );
}
export default App;
